// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import * as bootstrap from 'bootstrap';

// Enable Bootstrap Popovers
var popoverTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="popover"]')
);
popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6WEiU8JbrnGoFt90hPYVUbx78Sj8EEzo",
  authDomain: "edodsgarage.firebaseapp.com",
  projectId: "edodsgarage",
  storageBucket: "edodsgarage.appspot.com",
  messagingSenderId: "57972879271",
  appId: "1:57972879271:web:be989af7baa4a676379082",
  measurementId: "G-SVQESFQ5TD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
